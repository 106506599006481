@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CodeNext';
  font-style: normal;
  font-weight: 400;
  src: local('Code Next'), url('/assets/fonts/CodeNextBook.otf') format('opentype');
}

@font-face {
  font-family: 'CodeNext';
  font-style: normal;
  font-weight: 500;
  src: local('Code Next'), url('/assets/fonts/CodeNextBook.otf') format('opentype');
}

@font-face {
  font-family: 'CodeNext';
  font-style: normal;
  font-weight: 600;
  src: local('Code Next'), url('/assets/fonts/CodeNext-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'CodeNext';
  font-style: normal;
  font-weight: 700;
  src: local('Code Next'), url('/assets/fonts/CodeNextHeavy.otf') format('opentype');
}

html {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.button-frame {
  background: linear-gradient(85.52deg, #fff2ba 2.92%, #a8eae2 82.98%);
  box-shadow: inset 0 -4px 20px rgba(118, 217, 223, 0.7), inset 0 10px 10px 3px rgba(255, 255, 255, 0.85);
}
.button-frame-disabled {
  background: #bcefea;
  opacity: 0.6;
  box-shadow: inset 0 -4px 20px rgba(118, 217, 223, 0.7), inset 0 10px 10px 3px rgba(255, 255, 255, 0.85);
}

.button-animate-frame {
  background: linear-gradient(85.52deg, #fff2ba 2.92%, #a8eae2 82.98%);
  background-size: 400% 400%;
  box-shadow: inset 0 -4px 20px rgba(118, 217, 223, 0.7), inset 0 10px 10px 3px rgba(255, 255, 255, 0.85);
}

.feature-background {
  background: linear-gradient(359.21deg, #fff9e8 0%, #ffffff 57.73%, #afece6 97.82%);
}

.coming-soon-card {
  box-shadow: 0 4px 20px rgba(160, 196, 199, 0.5);
}

.gradient-header {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(249.37deg, #ff3d00 -5.61%, #ffe455 48.59%, #ff8a00 114.56%);
  -webkit-background-clip: text;
}

.text-container > * .editor-container > * {
  text-align: left;
}
